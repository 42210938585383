import {
	SimpleForm,
	FileInput,
	useDataProvider,
	SelectInput,
	Show,
	useNotify,
	FileField,
	TextInput,
	Edit,
	AutocompleteInput,
} from "react-admin";
import {
	ActionTitles,
	BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS,
	CurrencyChoices,
	DEFAULT_LOCALE,
	ENABLED_FEATURES_ENUM,
	FILE_TYPE,
	FORBIDDEN_ERROR_CODE,
	SUB_RESOURCES,
	SUPPORTED_CURRENCIES
} from "../Constants";
import {
	baseDarkButtonStyle,
	contentCenter,
	dialog,
	width,
	margin2,
	padding0,
	padding2,
	simpleForm,
	textColor,
	fileUploadLabel,
	tooltipLabelStyle,
	smallHelperText,
	mandatoryInputTitleWithTooltipIcon,
} from "../Styles";
import { requiredValidation } from "../../utils/util";
import { useWatch, useFormContext } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import React, { useEffect, useState } from "react";
import {
	Button,
	Divider,
	Grid,
	List,
	ListItem,
	Tooltip,
	Typography,
} from "@mui/material";
import CustomDialog from "../../utils/CustomDialog";
import { MatterAccountsGrid } from "./MatterAccountsGrid";
import { useParams } from "react-router-dom";
import isUndefined from "lodash/isUndefined";
import ListActions from "../common/ListActions";
import { FileStatus } from "./FileStatus";
import { isFeatureEnabled } from "../DataProvider";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import { CsvTemplateDownloadButton } from "./CsvTemplateDownloadButton";

let nextId = 1;

export const FileUpload = () => {
	const [data, setData] = useState();
	const [account, setAccount] = useState(null);
	const [accountsList, setAccountList] = useState([]);
	const { id } = useParams();
	const dataProvider = useDataProvider();
	const [accountRefresh, setAccountRefresh] = useState(false);

	const fetchUserSummary = () => {
		dataProvider
			.getMappedUsersSummary(`client/mapped-users`)
			.then(({ data }) => {
				setData(data);
			})
	}

	useEffect(() => {
		fetchUserSummary();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const refreshPage = () => {
		setAccountRefresh(!accountRefresh);
	};

	return (
		<>
			<Edit
				actions={
					<ListActions
						title={ActionTitles.MATTER_UPLOAD_STATEMENTS_AND_FILES}
						showViewButton={true}
					/>
				}
				sx={contentCenter}
			>
				<SimpleForm
					toolbar={null}
					sx={simpleForm}
					warnWhenUnsavedChanges
				>
					<FileUploadForm
						matterId={id}
						setAccountList={setAccountList}
						account={account}
						setAccount={setAccount}
						accountRefresh={accountRefresh}
					/>
				</SimpleForm>
			</Edit>
			{accountsList && accountsList.total > 0 && (
				<>
					<Typography variant="h6" sx={margin2.top}>
						Bank Accounts
					</Typography>
					<Show
						actions={null}
						renderButtonAndSearch={false}
						toolbar={null}
					>
						<MatterAccountsGrid
							matterId={id}
							usersMap={data}
							accountsList={accountsList}
							setAccount={setAccount}
							refresh={refreshPage}
							fileUpload={true}
						/>
					</Show>
				</>
			)}
		</>
	);
};

const FileUploadForm = (props) => {
	const { matterId, setAccountList, account, setAccount, accountRefresh } = props;
	const [files, setFiles] = useState([]);
	const [fileStatus, setFileStatus] = useState([]);
	const [jobQueue, setJobQueue] = useState([]);
	const values = useWatch();
	const form = useFormContext();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const isBankStatementUploadAllowed = isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT);
	const isCSVUploadAllowed = isFeatureEnabled(ENABLED_FEATURES_ENUM.IMPORT_CSV);
	const [loading, setLoading] = useState(false);

	const assignStatus = (id, status) => {
		setFiles(prevFiles => {
			return prevFiles.map(file =>
				file.id === id ? { ...file, status, file: undefined } : file
			);
		})
	}

	const mergeJobsIntoFiles = (data) => {
		setFiles(prevFiles => {
			const csvFiles = prevFiles.filter(file => file.type === FILE_TYPE.CSV)
			return csvFiles.concat(data);
		})
	}

	const uploadFiles = () => {
		if (values?.file) {
			const array = [];
			values.file.forEach((element) => {
				array.push({
					id: nextId++,
					file: element,
					title: element.title,
					created: moment().tz(DEFAULT_LOCALE).format("YYYY-MM-DD HH:mm:ss A"),
					type: FILE_TYPE.CSV,
				});
			});

			setFiles(prevFiles => {
				return [...prevFiles, ...array]
			});

			array.forEach(element => {
				dataProvider.uploadFiles({ ...element, matterId: matterId })
					.then((data) => {
						assignStatus(element.id, data?.status);
						(data.status === null || data.status.length === 0) && fetchAccounts();
					}).catch((error) => {
						assignStatus(element.id, "ERROR");
						notify('Something went wrong while uploading ' + element.title, { type: "warning", autoHideDuration: 1000 });
					})
			});
		}
		form.resetField("file");
	};

	const uploadPDFFiles = () => {
		setLoading(true);
		const { accountName, accountNumber, bankName, sortCode, currency } = values;

		if (values?.pdfFile) {
			dataProvider.uploadPdfFiles({ file: values.pdfFile, matterId: matterId, accountName, accountNumber, bankName, sortCode, currency })
				.then((data) => {
					setJobQueue(prevElement => [...prevElement, data])
					setFiles(prevFiles => [...prevFiles, data]);					
				}).catch((error) => {
					if(error?.code === FORBIDDEN_ERROR_CODE){
						notify(error?.description, { type: "warning", autoHideDuration: 3000 });
					}else{
						notify('Something went wrong while uploading bank statements', { type: "warning", autoHideDuration: 3000 });
					}					
				}).finally(() => {
					setLoading(false);
					form.resetField("pdfFile");
					form.resetField("accountNumber");
					form.resetField("accountName");
					form.resetField("bankName");
					form.resetField("sortCode");
					form.resetField("currency");
				});
		}
	};

	const fetchAccounts = () => {
		dataProvider.getManyReference(SUB_RESOURCES.ACCOUNTS, { id: matterId }).then((data) => {
			setAccountList(data);
		}).catch((error) => {
			notify('Something went wrong while fetching matter accounts.', { type: "warning", autoHideDuration: 1000 });
		});
	}

	const fetchBankStatementJobStatus = () => {
		dataProvider.getBankStatementJobStatus({ id: matterId })
			.then(({ data }) => {
				mergeJobsIntoFiles(data);
				setFileStatus(data);
				setJobQueue(prevElement => {

					const inProgressElements = data
						.filter(element => element.status === BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.IN_PROGRESS)
						.map(element => { return { ...element, retryCount: 0 } });

					return [...prevElement, ...inProgressElements];
				})
			}).catch((error) => {
				notify('Something went wrong while fetching bank statement job status.', { type: "warning", autoHideDuration: 1000 });
			});
	}

	React.useEffect(() => {
		fetchAccounts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountRefresh])

	React.useEffect(() => {
		fetchBankStatementJobStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function containsLiveJob(jobs) {
		return jobs.some((job) => job.retryCount < process.env.REACT_APP_BANK_STATEMENT_RETRY_ATTEMPTS);
	}

	useEffect(() => {
		if (containsLiveJob(jobQueue)) {
			const intervalId = setInterval(() => {

				dataProvider.getBankStatementJobStatus({ id: props.matterId })
					.then(({ data }) => {

						setFileStatus(data);
						mergeJobsIntoFiles(data);

						const jobsToRemove = jobQueue
							.flatMap(job =>
								data.filter(element => element.jobId === job.jobId &&
									element.status !== BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.IN_PROGRESS)
							)

						const jobIdToRemove = jobsToRemove.map(ele => ele.jobId);

						setJobQueue(prevJobs => {
							return prevJobs
								.filter((job) => !jobIdToRemove.includes(job.jobId))
								.map((job) => ({
									...job,
									retryCount: job.retryCount + 1,
								}));
						});

						!isEmpty(jobsToRemove) && fetchAccounts();
					})
			}, process.env.REACT_APP_BANK_STATEMENT_TIMEOUT * 1000);

			return () => clearInterval(intervalId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobQueue, props.matterId, dataProvider]);

	useEffect(() => {

		const deadJobs = jobQueue.filter((job) => {
			const jobElement = fileStatus.find((element) => element.jobId === job.jobId);
			return job.retryCount >= process.env.REACT_APP_BANK_STATEMENT_RETRY_ATTEMPTS &&
				jobElement?.status === BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.IN_PROGRESS;
		});

		const deadJobIds = deadJobs.map(ele => ele.jobId);

		setFiles(prevFiles => {

			const updatedFiles = prevFiles
				.map(file => (deadJobIds.includes(file.jobId) &&
					file.status === BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.IN_PROGRESS)
					? { ...file, status: BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS.TIMEOUT }
					: file)

			return updatedFiles;
		})

	}, [jobQueue, fileStatus]);

	return (
		<>
			<Grid container flexGrow={1}>
				<Grid item xs={12}>
					<List sx={padding0.y}>
						{isCSVUploadAllowed && <ListItem sx={padding2.y} key="uploadTransactions">
							<Grid container alignItems="center" spacing={2} wrap="wrap">
								<Grid item xs={12} md={5} lg={4} sx={textColor.gray}>
									Add Transactions From Files
								</Grid>
								<CSVUploadDialog uploadFiles={uploadFiles} />
							</Grid>
						</ListItem>}
						{(isCSVUploadAllowed && isBankStatementUploadAllowed) && <ListItem sx={padding2.y} key="or">
							<Grid container>
								<Grid item xs={12} md={3} sx={[textColor.gray, padding2.left]}>
									or
								</Grid>
							</Grid>
						</ListItem>}
						{isBankStatementUploadAllowed && <ListItem sx={padding2.y} key="uploadTransactions2">
							<Grid container alignItems="center" spacing={2} wrap="wrap">
								<Grid item xs={12} md={5} lg={4} sx={textColor.gray}>
									Convert Bank Statements
								</Grid>
								<PDFUploadDialog uploadPDFFiles={uploadPDFFiles} loading={loading} account={account} setAccount={setAccount} />
							</Grid>
						</ListItem>}
						{(files && files.length) ?
							<ListItem sx={padding2.y} key="fileUploaded">
								<Grid container>
									<Grid item xs={12}>
										<FileStatus files={files} />
									</Grid>
								</Grid>
							</ListItem> : null
						}
					</List>
				</Grid>
			</Grid>
		</>
	);
};

const CSVUploadDialog = (props) => {
	const { uploadFiles } = props;
	const [open, setOpen] = useState(false);
	const values = useWatch();

	const handleDialogClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	return (
		<Grid item xs={12} md={7} lg={5} xl={4}>
			<Grid container>
				<Grid item xs={12} md={9}>
					<Button
						sx={{
							...baseDarkButtonStyle(),
							marginLeft: "0 !important",
						}}
						startIcon={<FileUploadIcon />}
						size="small"
						onClick={() => {
							handleOpen();
						}}
					>
						Import CSV files
					</Button>
					<CsvTemplateDownloadButton />
					<CustomDialog
						openDialog={open}
						closeDialog={handleDialogClose}
						title={ActionTitles.MATTER_ADD_TRANSACTIONS_FROM_FILE}
						cancelBtnTitle={"Close"}
						content={
							<CSVUploadFormGrid />
						}
						dialogPaperProps={dialog}
						handleSubmit={() => {
							uploadFiles();
							handleDialogClose();
						}}
						submitBtnTitle={ActionTitles.MATTER_BANK_STATEMENT_IMPORT}
						submitBtnIcon={<FileUploadIcon />}
						invalid={isUndefined(values?.file)}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

const CSVUploadFormGrid = () => {
	return (
		<Grid container flexGrow={1}>
			<Grid item xs={12}>
				<List sx={padding0.y}>
					<ListItem sx={padding2.y} key="files">
						<Grid container alignItems="start">
							<Grid item xs={12} md={4} sx={{ ...textColor.gray, ...fileUploadLabel }}>
								CSV files
								<span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<FileInput
									multiple
									name="file"
									source="file"
									label={false}
									variant="outlined"
									size="medium"
									validate={requiredValidation}
									accept="text/csv"
									helperText={false}
									placeholder={<p>Drop files to upload, or click to select</p>}
								>
									<FileField source="src" title="title" />
								</FileInput>
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	);
}

const PDFUploadDialog = (props) => {
	const { loading, uploadPDFFiles, account, setAccount } = props;
	const [open, setOpen] = useState(false);
	const dataProvider = useDataProvider();
	const [bankChoices, setBankChoices] = useState([]);
	const values = useWatch();

	const fetchBanks = () => {
		dataProvider.getAllBanks({ fetchForScanning: true })
			.then((bankData) => {
				const choices = Array.from(bankData.data, ([id, name]) => ({ id, name }));
				setBankChoices(choices);
			})
	}

	useEffect(() => {
		fetchBanks()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (account !== null)
			handleDialogOpen();
	}, [account])

	useEffect(() => {
		if (!loading)
			handleDialogClose();
		// eslint-disable-next-line
	}, [loading])

	const handleDialogClose = () => {
		if (account !== null)
			setAccount(null);
		setOpen(false);
	};

	const handleDialogOpen = () => {
		setOpen(true);
	};

	return (
		<Grid item xs={12} md={7} lg={5} xl={4}>
			<Grid container>
				<Grid item xs={12} md={9}>
					<Button
						sx={{
							...baseDarkButtonStyle(),
							marginLeft: "0 !important",
						}}
						startIcon={<FileUploadIcon />}
						size="small"
						onClick={() => {
							handleDialogOpen();
						}}
					>
						Import Bank Statements
					</Button>
					<CustomDialog
						openDialog={open}
						closeDialog={handleDialogClose}
						loading={loading}
						disableCancelButton={loading}
						disableSubmitButton={loading}
						title={ActionTitles.MATTER_BANK_STATEMENT_TITLE}
						cancelBtnTitle={"Close"}
						content={
							<PDFUploadFormGrid bankChoices={bankChoices} loading={loading} account={account} />
						}
						dialogPaperProps={dialog}
						handleSubmit={() => {
							uploadPDFFiles();
						}}
						submitBtnTitle={ActionTitles.MATTER_BANK_STATEMENT_CONVERT}
						submitBtnIcon={<FileUploadIcon />}
						invalid={isUndefined(values?.pdfFile) || isUndefined(values?.bankName) || isUndefined(values?.currency)}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

const PDFUploadFormGrid = (props) => {
	const { bankChoices, loading, account } = props;
	const [isLoading, setIsLoading] = useState(true);
	const form = useFormContext();

	useEffect(() => {
		if (account !== null) {
			form.setValue('bankName', account.bankName);
			form.setValue('currency', account.currency);
			form.setValue('accountNumber', account.accountNumber);
			form.setValue('sortCode', account.sortCode);
		}
		else {
			form.resetField('bankName');
			form.resetField('currency');
			form.resetField('accountNumber');
			form.resetField('sortCode');
		}
		setIsLoading(false);
		// eslint-disable-next-line
	}, [account]);

	return (!isLoading &&
		<Grid container flexGrow={1}>
			<Grid item xs={12}>
				<List sx={padding0.y}>
					<ListItem sx={padding2.y} key="files">
						<Grid container alignItems="start">
							<Grid item xs={12} md={4} sx={{ ...textColor.gray, ...fileUploadLabel }}>
								Bank Statements
								<span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<FileInput
									multiple
									name="pdfFile"
									source="pdfFile"
									label={false}
									variant="outlined"
									size="medium"
									validate={requiredValidation}
									accept="application/pdf"
									helperText={false}
									disabled={loading}
									placeholder={<p>Drop files to upload, or click to select</p>}
								>
									<FileField source="src" title="title" />
								</FileInput>
							</Grid>
						</Grid>
					</ListItem>
					<Divider light />
					<ListItem sx={padding2.y} key="bankName">
						<Grid container alignItems="center">
							<Grid item xs={12} md={4} sx={textColor.gray} style={{ display: "flex" }}>
								Bank Name
								<span style={mandatoryInputTitleWithTooltipIcon}>*</span>
								<Tooltip
									disableInteractive
									placement="right"
									title={
										<div sx={{ ...tooltipLabelStyle }}>
											Bank not in the list? Contact support@armalytix.com and we’ll see if we can add it.
										</div>
									}
								>
									<HelpOutlineIcon style={{ fontSize: "medium" }} />
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<AutocompleteInput
									source="bankName"
									choices={bankChoices}
									optionValue="name"
									variant="outlined"
									helperText={false}
									sx={width.fluid.w100}
									disabled={loading}
									isRequired
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider light />
					<ListItem sx={padding2.y} key="currency">
						<Grid container alignItems="center">
							<Grid item xs={12} md={4} sx={textColor.gray}>
								Currency
								<span style={{ ...textColor.red, marginLeft: '3px' }}>*</span>
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<SelectInput
									source="currency"
									choices={CurrencyChoices}
									defaultValue={SUPPORTED_CURRENCIES.POUND}
									variant="outlined"
									size="small"
									helperText={false}
									disabled={loading}
									isRequired
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider light />
					<ListItem sx={padding2.y} key="accountName">
						<Grid container alignItems="center">
							<Grid item xs={12} md={4} sx={textColor.gray}>
								Account Name
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<TextInput
									source="accountName"
									variant="outlined"
									helperText={<div style={smallHelperText}>Leave blank to try extracting from statement</div>}
									sx={width.fluid.w100}
									disabled={loading}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider light />
					<ListItem sx={padding2.y} key="accountNumber">
						<Grid container alignItems="center">
							<Grid item xs={12} md={4} sx={textColor.gray}>
								Account Number
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<TextInput
									source="accountNumber"
									variant="outlined"
									helperText={<div style={smallHelperText}>Leave blank to try extracting from statement</div>}
									sx={width.fluid.w100}
									disabled={loading}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider light />
					<ListItem sx={padding2.y} key="sortCode">
						<Grid container alignItems="center">
							<Grid item xs={12} md={4} sx={textColor.gray}>
								Sort Code
							</Grid>
							<Grid item xs={12} md={8} lg={8}>
								<TextInput
									source="sortCode"
									variant="outlined"
									helperText={<div style={smallHelperText}>Leave blank to try extracting from statement</div>}
									sx={width.fluid.w100}
									disabled={loading}
								/>
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</Grid>
		</Grid>
	);
}
